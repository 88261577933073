import "firebase/firestore";
import { Swing } from "./models/swing";
import ExcelJS from "exceljs";
import { formatDate } from "./utils/formatter";
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import localizedFormat from "dayjs/plugin/localizedFormat";
import localeData from "dayjs/plugin/localeData";
import locales from "dayjs/locale.json";
import app, { firestore } from "./base";
import { User } from "@firebase/auth";
import { getLocale } from "./utils/getLocale";
import {
  collection,
  orderBy,
  query,
  where,
  Timestamp,
  getDocs,
  QueryDocumentSnapshot,
  DocumentData,
  addDoc,
  limit,
  getDoc,
  doc,
} from "@firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { SessionData, SwingDeviation } from "./models/session";
import { Profile } from "./models/profile";
import { ReqInterface, ResInterface } from "./models/ambassador";
import { allMode } from "./constants/mode_data";
import { Contact } from "./models/contact";
import { getAverageValue } from "./utils/chart";

dayjs.extend(utc);
dayjs.extend(localizedFormat);
dayjs.extend(localeData);

for (const locale of locales) {
  require(`dayjs/locale/${locale.key}.js`);
}

type Doc = QueryDocumentSnapshot<DocumentData>;
enum LogType {
  INSIGHTS = "insights",
  DATA_DOWNLOAD = "dataDownload",
}

export const UOM = {
  Metric: "metric",
  Imperial: "imperial",
};

const environment = process.env.REACT_APP_API_ENVIRONMENT;

const exportSwingsToFile = async (
  user: User,
  startDate: Dayjs | null,
  endDate: Dayjs | null,
  unitOfMeasure: string,
  selectedProfileId: string,
  contact?: Contact
) => {
  if (!startDate || !endDate) return;

  const searchedEndDate = dayjs(endDate).add(1, "d").startOf("day").toDate();
  const searchStartDate = dayjs(startDate).startOf("day").toDate();
  const swingCollectionPath = `logs/${environment}/${
    contact?.contactUID ? contact.contactUID : user.uid
  }/data/swings`;
  const logCollectionPath = `logs/${environment}/${user.uid}/data/data-download-searches`;

  try {
    const swings = await GetSwings({
      collectionPath: swingCollectionPath,
      startTime: searchStartDate,
      endTime: searchedEndDate,
      unitOfMeasure,
      selectedProfileId,
      contact,
    });
    if (swings) {
      const exportedFileName = `deWiz swing ${formatDate(
        searchStartDate
      )}-${formatDate(searchedEndDate)}.xlsx`;
      await ExportSwingsToExcel(swings, exportedFileName);
      await AddLogToFirestore({
        uid: user.uid,
        logCollectionPath,
        startDate: searchStartDate,
        endDate: searchedEndDate,
        numberOfRecords: Math.max(swings.length - 1, 0),
        type: LogType.DATA_DOWNLOAD,
      });
    }
  } catch (error) {
    console.log(error);
    console.log("The log document could not be added to the database.");
  }
};

async function ExportSwingsToExcel(swings: Swing[], name: string) {
  const fileName = name || "Swings.xlsx";
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("Data");
  worksheet.insertRows(1, ConvertDictToList(swings));

  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], { type: "application/octet-stream" });
  saveAs(blob, fileName);
}

function ConvertDictToList(swingDict: Swing[]) {
  const columns = new Array(swingDict.length);

  for (let i = 0; i < columns.length; i++) {
    const rowSplit = swingDict[i];

    let rowDataTemp = [
      rowSplit["timestamp"],
      rowSplit["profile"],
      rowSplit["feature"],
      rowSplit["practiceSwing"],
      rowSplit["favorite"],
      rowSplit["hole"],
      rowSplit["club"],
      rowSplit["result"],
      rowSplit["transition"],
      rowSplit["lob"],
      rowSplit["tempoRatio"],
      rowSplit["backswingDuration"],
      rowSplit["downswingDuration"],
      rowSplit["startToImpact"],
      rowSplit["tempoPause"],
      rowSplit["maxHandSpeed"],
      rowSplit["maxHandSpeedDistanceToImpact"],
      rowSplit["maxBackswingWidth"],
      rowSplit["backswingPlane"],
      rowSplit["IDDx"],
      rowSplit["IDDy"],
      rowSplit["steepeningShallowing"],
    ];

    columns[i] = rowDataTemp;
  }

  return columns;
}

function saveAs(blob: Blob, fileName: string) {
  const url = window.URL.createObjectURL(blob);

  const anchorElem = document.createElement("a");
  anchorElem.href = url;
  anchorElem.download = fileName;

  document.body.appendChild(anchorElem);
  anchorElem.click();
  anchorElem.remove();

  // On Edge, revokeObjectURL should be called only after
  // a.click() has completed, atleast on EdgeHTML 15.15048
  setTimeout(function () {
    window.URL.revokeObjectURL(url);
  }, 1000);
}

function round(number: number, decimals: number) {
  return parseFloat(number.toFixed(decimals));
}

async function GetSwings({
  collectionPath,
  startTime,
  endTime,
  unitOfMeasure,
  selectedProfileId,
  contact,
  sessionDocumentId,
}: {
  collectionPath: string;
  startTime?: Date;
  endTime?: Date;
  unitOfMeasure: string;
  selectedProfileId?: string;
  contact?: Contact;
  sessionDocumentId?: string;
}) {
  let start = startTime ? Timestamp.fromDate(startTime) : null;
  const end = endTime ? Timestamp.fromDate(endTime) : null;

  // const contactMonitoringPermissionTimestamp =
  //   contact?.contactMonitoringPermissionTimestamp;
  // if (
  //   start &&
  //   contactMonitoringPermissionTimestamp &&
  //   start.toMillis() < contactMonitoringPermissionTimestamp.toMillis()
  // ) {
  //   start = contactMonitoringPermissionTimestamp;
  // }

  let docs: Swing[] = [];

  const q =
    start && end
      ? query(
          collection(firestore, collectionPath),
          orderBy("metadata.timestamp", "desc"),
          where("metadata.timestamp", ">=", start),
          where("metadata.timestamp", "<=", end),
          where(
            "metadata.profileId",
            "==",
            contact?.contactProfileId
              ? contact.contactProfileId
              : selectedProfileId
          )
        )
      : query(
          collection(firestore, collectionPath),
          orderBy("metadata.timestamp", "asc"),
          where("metadata.sessionDocumentId", "==", sessionDocumentId)
        );

  const querySnapshot = await getDocs(q);

  if (!sessionDocumentId) {
    addHeaders(docs, unitOfMeasure);
  }

  querySnapshot.forEach(function (doc) {
    let docVal: Swing = {} as Swing;
    // console.log(doc.get("processedGolfData"));
    docVal["profile"] = getProfileName(doc);
    docVal["feature"] = getFeatureName(doc);
    docVal["timestamp"] = getTimestamp(doc, "metadata.timestamp");
    docVal["transition"] = getTransition(doc, unitOfMeasure);
    docVal["lob"] = getLob(doc, unitOfMeasure);
    docVal["tempoRatio"] = getTempoRatio(doc);
    docVal["backswingDuration"] = getBackswingDuration(doc);
    docVal["downswingDuration"] = getDownswingDuration(doc);
    docVal["startToImpact"] = getStartToImpact(doc);
    docVal["tempoPause"] = getTempoPause(doc);
    docVal["maxHandSpeed"] = getMaxHandSpeed(doc);
    docVal["maxHandSpeedDistanceToImpact"] = getMaxHandSpeedDistanceToImpact(
      doc,
      unitOfMeasure
    );
    docVal["maxBackswingWidth"] = getMaxBackswingWidth(doc, unitOfMeasure);
    docVal["backswingPlane"] = getBackswingPlane(doc);
    docVal["club"] = getClub(doc);
    docVal["favorite"] = getFavorite(doc);
    docVal["IDDx"] = getIDDx(doc);
    docVal["IDDy"] = getIDDy(doc);
    docVal["steepeningShallowing"] = getSteepeningShallowing(doc);
    docVal["powerScorePredictedChs"] = getPowerScorePredictedCHS(doc);
    docVal["practiceSwing"] = getPracticeSwing(doc);
    docVal["hole"] = getHole(doc);
    docVal["result"] = getResult(doc);
    docVal["location"] = doc.get("metadata.location");
    docVal["id"] = doc.get("metadata.id");
    docs.push(docVal);
  });
  return docs;
}

function getTimestamp(doc: Doc, path: string) {
  const currentLocale = getLocale();
  const timestamp = doc.get(path).toDate();
  const timestampLocal = dayjs
    .utc(timestamp)
    .local()
    .locale(currentLocale)
    .format("L LTS");
  return timestampLocal;
}

function getTransition(doc: Doc, unitOfMeasure: string) {
  let transition = doc.get("processedGolfData.transition.transition");

  if (transition === undefined) {
    transition = doc.get("golfData.transitionAnalysis.resultValue");
  }

  if (unitOfMeasure === UOM.Imperial) {
    transition /= 2.54;
  }

  if (typeof transition === "number") {
    transition = round(100 * transition, 1);
  }

  return transition;
}

function getLob(doc: Doc, unitOfMeasure: string) {
  let lob = doc.get("processedGolfData.length_of_swing.lob");

  if (lob === undefined) {
    lob = doc.get("golfData.lobAnalysis.resultDistance");
  }

  if (unitOfMeasure === UOM.Imperial) {
    lob /= 2.54;
  }

  if (typeof lob === "number") {
    lob = round(100 * lob, 1);
  }

  return lob;
}

function getTempoRatio(doc: Doc) {
  let tempoRatio = doc.get("processedGolfData.tempo.tempo_ratio");

  if (
    tempoRatio === undefined &&
    doc.get("golfData.tempoAnalysis.backswingDuration") !== undefined &&
    doc.get("golfData.tempoAnalysis.downswingDuration") !== undefined
  ) {
    tempoRatio =
      doc.get("golfData.tempoAnalysis.backswingDuration") /
      doc.get("golfData.tempoAnalysis.downswingDuration");
  }

  if (typeof tempoRatio === "number") {
    tempoRatio = round(tempoRatio, 2);
  }

  return tempoRatio;
}

function getBackswingDuration(doc: Doc) {
  let backswingDuration = doc.get("processedGolfData.tempo.tempo_bs");

  if (backswingDuration === undefined) {
    backswingDuration = doc.get("golfData.tempoAnalysis.backswingDuration");
  }

  if (typeof backswingDuration === "number") {
    backswingDuration = round(backswingDuration, 2);
  }

  return backswingDuration;
}

function getDownswingDuration(doc: Doc) {
  let downswingDuration = doc.get("processedGolfData.tempo.tempo_ds");

  if (downswingDuration === undefined) {
    downswingDuration = doc.get("golfData.tempoAnalysis.downswingDuration");
  }

  if (typeof downswingDuration === "number") {
    downswingDuration = round(downswingDuration, 2);
  }

  return downswingDuration;
}

function getStartToImpact(doc: Doc) {
  let startToImpact = doc.get("processedGolfData.tempo.start_to_impact");

  if (
    startToImpact === undefined &&
    doc.get("golfData.tempoAnalysis.backswingDuration") !== undefined &&
    doc.get("golfData.tempoAnalysis.downswingDuration") !== undefined
  ) {
    startToImpact =
      doc.get("golfData.tempoAnalysis.backswingDuration") +
      doc.get("golfData.tempoAnalysis.downswingDuration");
  }

  if (typeof startToImpact === "number") {
    startToImpact = round(startToImpact, 2);
  }

  return startToImpact;
}

function getTempoPause(doc: Doc) {
  let tempoPause = doc.get("processedGolfData.tempo.tempo_pause");

  if (tempoPause === undefined) {
    tempoPause = doc.get("golfDataCalculatedValues.tempoPause");
  }

  if (typeof tempoPause === "number") {
    tempoPause = round(tempoPause, 2);
  }

  return tempoPause;
}

function getMaxHandSpeed(doc: Doc) {
  let maxHandSpeed = doc.get("processedGolfData.hand_speed.max_hs");

  if (maxHandSpeed === undefined) {
    maxHandSpeed = doc.get("golfDataCalculatedValues.handSpeed.max");
  }

  if (typeof maxHandSpeed === "number") {
    maxHandSpeed = round(2.23694 * maxHandSpeed, 1);
  }

  return maxHandSpeed;
}

function getMaxHandSpeedDistanceToImpact(doc: Doc, unitOfMeasure: string) {
  let maxHandSpeedDistanceToImpact = doc.get(
    "processedGolfData.hand_speed.max_hs_dist_to_impact"
  );

  if (maxHandSpeedDistanceToImpact === undefined) {
    maxHandSpeedDistanceToImpact = doc.get(
      "golfDataCalculatedValues.handSpeed.maxSpeedDistanceToImpact"
    );
  }

  if (unitOfMeasure === UOM.Imperial) {
    maxHandSpeedDistanceToImpact /= 2.54;
  }

  if (typeof maxHandSpeedDistanceToImpact === "number") {
    maxHandSpeedDistanceToImpact = round(100 * maxHandSpeedDistanceToImpact, 0);
  }

  return maxHandSpeedDistanceToImpact;
}

function getMaxBackswingWidth(doc: Doc, unitOfMeasure: string) {
  let maxBackswingWidth = doc.get(
    "processedGolfData.swing_width.max_backswing_width"
  );

  if (maxBackswingWidth === undefined) {
    maxBackswingWidth = doc.get("golfDataCalculatedValues.maxBackswingWidth");
  }

  if (unitOfMeasure === UOM.Imperial) {
    maxBackswingWidth /= 2.54;
  }

  if (typeof maxBackswingWidth === "number") {
    maxBackswingWidth = round(100 * maxBackswingWidth, 0);
  }

  return maxBackswingWidth;
}

function getBackswingPlane(doc: Doc) {
  let backswingPlane = doc.get(
    "processedGolfData.swing_plane.backswing_plane_angle_2d"
  );

  if (backswingPlane === undefined) {
    backswingPlane = doc.get("golfData.planeFit.backswingPlane.z");

    if (typeof backswingPlane === "number") {
      backswingPlane = (Math.acos(backswingPlane) * 180) / Math.PI;
    }
  } else {
    backswingPlane *= 180 / Math.PI;
  }

  if (typeof backswingPlane === "number") {
    backswingPlane = round(backswingPlane, 0);
  }

  return backswingPlane;
}

function getClub(doc: Doc) {
  return doc.get("metadata.club.name");
}

function getFavorite(doc: Doc) {
  let favorite = doc.get("metadata.favorite");

  if (favorite === undefined) {
    favorite = false;
  }

  return favorite;
}

function getIDDx(doc: Doc) {
  let iddx = doc.get("processedGolfData.position_dependent_values.IDDx");

  if (typeof iddx === "number") {
    iddx = round((iddx * 180) / Math.PI, 0);
  }

  return iddx;
}

function getIDDy(doc: Doc) {
  let iddy = doc.get("processedGolfData.position_dependent_values.IDDy");

  if (typeof iddy === "number") {
    iddy = round((iddy * 180) / Math.PI, 0);
  }

  return iddy;
}

function getSteepeningShallowing(doc: Doc) {
  let steepeningShallowing = doc.get("processedGolfData.pron_sup.SS_at_10cm");

  if (typeof steepeningShallowing === "number") {
    steepeningShallowing = round((steepeningShallowing * 180) / Math.PI, 1);
  }

  return steepeningShallowing;
}

function getPowerScorePredictedCHS(doc: Doc) {
  if (doc.get("metadata.club.id") === 1) {
    let powerScorePredictedCHS = doc.get("processedGolfData.power_score.chs");

    if (typeof powerScorePredictedCHS === "number") {
      powerScorePredictedCHS = round(2.23694 * powerScorePredictedCHS, 1);
    }

    return powerScorePredictedCHS;
  } else {
    return undefined
  }
}

function getPracticeSwing(doc: Doc) {
  let wasImpact = doc.get("processedGolfData.ballhit.was_impact");
  let practiceSwing = false;

  if (wasImpact === undefined) {
    wasImpact = doc.get("golfData.ballHitAnalysis.impactType");
  }

  if (wasImpact !== undefined) {
    practiceSwing = wasImpact === 0;
  }

  return practiceSwing;
}

function getHole(doc: Doc) {
  return doc.get("metadata.hole.id");
}

const getResultNameMap = () => {
  const resultMap = new Map();
  resultMap.set("farLeft", "Far left");
  resultMap.set("left", "Left");
  resultMap.set("normal", "Good");
  resultMap.set("right", "Right");
  resultMap.set("farRight", "Far right");
  resultMap.set("other", "Other");

  return resultMap;
};

function getResult(doc: Doc) {
  let deviation = doc.get("metadata.deviation");

  if (deviation) {
    deviation = getResultNameMap().get(deviation);
  }

  return deviation;
}

async function AddLogToFirestore({
  uid,
  logCollectionPath,
  startDate,
  endDate,
  numberOfRecords,
  type,
}: {
  uid: string;
  logCollectionPath: string;
  startDate: Date;
  endDate: Date;
  numberOfRecords: number;
  type: LogType;
}) {
  function DateToTimestamp(date: Date) {
    return Timestamp.fromDate(date);
  }

  const dataObject = {
    firestoreUID: uid,
    timestamp: DateToTimestamp(new Date()),
    startTime: DateToTimestamp(startDate),
    endTime: DateToTimestamp(endDate),
    numberOfRecords,
    type,
  };

  addDoc(collection(firestore, logCollectionPath), dataObject);
}

function getProfileName(doc: Doc) {
  const profile = doc.get("settings.genericAppSettings.profile.name");
  return profile ? profile : "";
}

const getFeatureNameMap = () => {
  const featureMap = new Map();
  featureMap.set("TRANSITION", "Transition");
  featureMap.set("NONE", "Discovery");
  featureMap.set("LENGTH_OF_BACKSWING", "Length of backswing");
  featureMap.set("TEMPO", "Tempo");
  featureMap.set("DISTANCE_WEDGES", "DistWedges");
  featureMap.set("DIST_WEDGES_LOB_CHALLENGE", "Distwedges challenge");
  featureMap.set("ON_COURSE", "On-course");
  featureMap.set("TOUR_TEMPO_CHALLENGE", "Tempo challenge");

  return featureMap;
};

function getFeatureName(doc: Doc) {
  let feature = doc.get("settings.featuresOnOffSettings.activatedFeature");
  feature = getFeatureNameMap().get(feature);

  if (!feature) {
    if (doc.get("settings.featuresOnOffSettings.transition")) {
      feature = "Transition";
    } else if (doc.get("settings.featuresOnOffSettings.discovery")) {
      feature = "Discovery";
    } else if (doc.get("settings.featuresOnOffSettings.lengthOfBackSwing")) {
      feature = "Length of backswing";
    } else if (doc.get("settings.featuresOnOffSettings.tempo")) {
      feature = "Tempo";
    } else if (doc.get("settings.featuresOnOffSettings.distanceWedges")) {
      feature = "DistWedges";
    } else if (
      doc.get("settings.featuresOnOffSettings.distWedgesLOBChallenge")
    ) {
      feature = "Distwedges challenge";
    } else if (doc.get("settings.featuresOnOffSettings.onCourse")) {
      feature = "On-course";
    } else if (doc.get("settings.featuresOnOffSettings.tourTempoChallenge")) {
      feature = "Tempo challenge";
    }
  }

  return feature ? feature : "";
}

function addHeaders(docs: Swing[], unitOfMeasure: string) {
  docs.push({
    transition: [
      "Transition",
      unitOfMeasure === UOM.Metric ? "(cm)" : "(in)",
    ].join(" "),
    lob: [
      "Length of backswing",
      unitOfMeasure === UOM.Metric ? "(cm)" : "(in)",
    ].join(" "),
    tempoRatio: "Tempo ratio",
    backswingDuration: "Backswing duration (s)",
    downswingDuration: "Downswing duration (s)",
    startToImpact: "Start to impact (s)",
    tempoPause: "Tempo pause (s)",
    maxHandSpeed: "Max hand speed (mph)",
    maxHandSpeedDistanceToImpact: [
      "Distance to impact",
      unitOfMeasure === UOM.Metric ? "(cm)" : "(in)",
    ].join(" "),
    maxBackswingWidth: [
      "Max backswing width",
      unitOfMeasure === UOM.Metric ? "(cm)" : "(in)",
    ].join(" "),
    backswingPlane: "Backswing plane",
    club: "Club",
    favorite: "Favorite",
    IDDx: "IDDx",
    IDDy: "IDDy",
    steepeningShallowing: "Steepening/Shallowing",
    powerScorePredictedChs: "Club Head Speed (mph)",
    timestamp: "Timestamp",
    practiceSwing: "Practice swing",
    profile: "Profile",
    feature: "Mode",
    hole: "Hole",
    result: "Result",
  });
}

const getFeatureSessionNameMap = () => {
  const featureMap = new Map();
  allMode.map((mode) => featureMap.set(mode.id, mode.value));

  return featureMap;
};

function getFeatureNameDiscover(doc: Doc) {
  let feature = doc.get("session.trainingFeature");
  feature = getFeatureSessionNameMap().get(feature) || feature;
  return feature ? feature : "";
}

const getAveragesPerClub = (doc: Doc, unitOfMeasure: string) => {
  const averagesPerClub = doc.get("session.results.averagesPerClub");
  if (averagesPerClub && typeof averagesPerClub === "object") {
    Object.keys(averagesPerClub).forEach((club) => {
      const clubData = averagesPerClub[club];
      let isDriver = club === '1';
      averagesPerClub[club]["transition"] = getAverageValue(
        clubData,
        "transition",
        unitOfMeasure
      );
      averagesPerClub[club]["lengthOfBackswing"] = getAverageValue(
        clubData,
        "lengthOfSwingBackswing",
        unitOfMeasure
      );
      averagesPerClub[club]["tempoRatio"] = getAverageValue(
        clubData,
        "tempoRatio",
        unitOfMeasure
      );
      averagesPerClub[club]["startToImpact"] = getAverageValue(
        clubData,
        "tempoStartToImpact",
        unitOfMeasure
      );
      averagesPerClub[club]["backSwingDuration"] = getAverageValue(
        clubData,
        "tempoBackswing",
        unitOfMeasure
      );
      averagesPerClub[club]["downSwingDuration"] = getAverageValue(
        clubData,
        "tempoDownswing",
        unitOfMeasure
      );
      averagesPerClub[club]["maxHandSpeed"] = getAverageValue(
        clubData,
        "handSpeedMax",
        unitOfMeasure
      );
      averagesPerClub[club]["maxHandSpeedDistanceToImpact"] = getAverageValue(
        clubData,
        "handSpeedMaxInDownswingDistanceToImpact",
        unitOfMeasure
      );
      if (isDriver) {
        averagesPerClub[club]["powerScorePredictedChs"] = getAverageValue(
          clubData,
          "powerScorePredictedCHS",
          unitOfMeasure
        );
      }
      averagesPerClub[club]["maxBackSwingWidth"] = getAverageValue(
        clubData,
        "backswingWidthMax",
        unitOfMeasure
      );
      averagesPerClub[club]["backSwingPlane"] = getAverageValue(
        clubData,
        "backswingPlane2D",
        unitOfMeasure
      );
      averagesPerClub[club]["IDDx"] = getAverageValue(
        clubData,
        "iddx",
        unitOfMeasure
      );
      averagesPerClub[club]["IDDy"] = getAverageValue(
        clubData,
        "iddy",
        unitOfMeasure
      );
      averagesPerClub[club]["steepeningShallowing"] = getAverageValue(
        clubData,
        "steepeningShallowing10cmInDownswing",
        unitOfMeasure
      );
    });
  }
  return averagesPerClub;
};

export const getSessionData = async ({
  user,
  startDate,
  endDate,
  desc,
  fetchLimit,
  unitOfMeasure,
  profileDocumentId,
  contact,
  isInitialDate = false,
}: {
  user: User;
  startDate: Dayjs | null;
  endDate: Dayjs | null;
  desc: boolean;
  fetchLimit: number;
  unitOfMeasure: string;
  profileDocumentId: string;
  contact?: Contact;
  isInitialDate?: boolean;
}) => {
  if (!startDate || !endDate) return;
  const searchedEndDate = dayjs(endDate).add(1, "d").startOf("day").toDate();
  const searchedStartDate = dayjs(startDate).startOf("day").toDate();
  try {
    let start = Timestamp.fromDate(searchedStartDate);

    // const contactMonitoringPermissionTimestamp =
    //   contact?.contactMonitoringPermissionTimestamp;
    // if (
    //   contactMonitoringPermissionTimestamp &&
    //   start.toMillis() < contactMonitoringPermissionTimestamp.toMillis()
    // ) {
    //   start = contactMonitoringPermissionTimestamp;
    // }

    const end = Timestamp.fromDate(searchedEndDate);
    const sessionPath = `logs/${environment}/${
      contact?.contactUID ? contact.contactUID : user.uid
    }/data/sessions`;

    const logCollectionPath = `logs/${environment}/${user.uid}/data/data-download-searches`;

    let docs: SessionData[] = [];
    const q = query(
      collection(firestore, sessionPath),
      orderBy("session.timestamp", desc ? "desc" : "asc"),
      where("session.timestamp", ">=", start),
      where("session.timestamp", "<=", end),
      where(
        "session.profileDocumentId",
        "==",
        contact?.contactProfileDocumentId
          ? contact.contactProfileDocumentId
          : profileDocumentId
      ),
      limit(fetchLimit)
    );

    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((doc) => {
      let docVal: SessionData = {} as SessionData;
      let isDriver = doc.get("session.club.id") === 1;
      let isOnCourseMode = doc.get("session.trainingFeature") === "onCourse";
      docVal["timestamp"] = isInitialDate
        ? doc.get("session.timestamp").toDate()
        : getTimestamp(doc, "session.timestamp");
      docVal["club"] = doc.get("session.club.name");
      docVal["feature"] = doc.get("session.trainingFeature");
      docVal["profileId"] = doc.get("session.profileId");
      docVal["transition"] = convertNumber(
        doc,
        unitOfMeasure,
        "session.results.averages.transition"
      );
      docVal["lengthOfBackswing"] = convertNumber(
        doc,
        unitOfMeasure,
        "session.results.averages.lengthOfSwingBackswing"
      );
      docVal["tempoRatio"] = roundNumber(
        doc,
        "session.results.averages.tempoRatio"
      );
      docVal["backSwingDuration"] = roundNumber(
        doc,
        "session.results.averages.tempoBackswing"
      );
      docVal["downSwingDuration"] = roundNumber(
        doc,
        "session.results.averages.tempoDownswing"
      );
      docVal["startToImpact"] = roundNumber(
        doc,
        "session.results.averages.tempoStartToImpact"
      );
      docVal["tempoPause"] = roundNumber(
        doc,
        "session.results.averages.tempoPause"
      );
      docVal["maxHandSpeed"] = roundNumber(
        doc,
        "session.results.averages.handSpeedMax",
        2.23694
      );
      docVal["maxHandSpeedDistanceToImpact"] = convertNumber(
        doc,
        unitOfMeasure,
        "session.results.averages.handSpeedMaxInDownswingDistanceToImpact",
        true
      );
      if (isDriver) {
        docVal["powerScorePredictedChs"] = roundNumber(
          doc,
          "session.results.averages.powerScorePredictedCHS",
          2.23694
        );
      } else if (isOnCourseMode) {
        docVal["powerScorePredictedChs"] = roundNumber(
          doc,
          "session.results.averagesPerClub.1.powerScorePredictedCHS",
          2.23694
        );
      }
      docVal["maxBackSwingWidth"] = convertNumber(
        doc,
        unitOfMeasure,
        "session.results.averages.backswingWidthMax",
        true
      );
      docVal["backSwingPlane"] = planeNumber(
        doc,
        "session.results.averages.backswingPlane2D"
      );
      docVal["IDDx"] = planeNumber(doc, "session.results.averages.iddx");
      docVal["IDDy"] = planeNumber(doc, "session.results.averages.iddy");
      docVal["steepeningShallowing"] = planeNumber(
        doc,
        "session.results.averages.steepeningShallowing10cmInDownswing",
        true
      );
      docVal["totalSwingsCount"] = doc.get(
        "session.statistics.totalSwingsCount"
      );
      docVal["mode"] = getFeatureNameDiscover(doc);
      docVal["description"] = doc.get("session.metadata.description") || "";
      docVal["state"] = doc.get("session.state");
      docVal["averagesPerClub"] = getAveragesPerClub(doc, unitOfMeasure);
      docVal["placemark"] = doc.get("session.placemark");
      docVal["documentId"] = doc.get("session.documentId");
      docVal["OS"] = doc.get("userAgent.OS");
      docVal["appVersion"] = doc.get("userAgent.appVersion");
      docs.push(docVal);
    });
    await AddLogToFirestore({
      uid: user.uid,
      logCollectionPath,
      startDate: searchedStartDate,
      endDate: searchedEndDate,
      numberOfRecords: Math.max(docs.length - 1, 0),
      type: LogType.INSIGHTS,
    });
    docs = docs.filter((doc) => doc.state === "finished");
    return {
      data: docs,
    };
  } catch (error) {
    console.log(error);
  }
};

const convertNumber = (
  doc: Doc,
  unitOfMeasure: string,
  path: string,
  zeroDecimal?: boolean
) => {
  let data = doc.get(path);
  if (unitOfMeasure === UOM.Imperial) {
    data /= 2.54;
  }
  if (typeof data === "number") {
    data = round(100 * data, zeroDecimal ? 0 : 1);
  }
  return data;
};

const roundNumber = (doc: Doc, path: string, ratio?: number) => {
  let data = doc.get(path);
  if (typeof data === "number") {
    data = ratio ? round(ratio * data, 1) : round(data, 2);
  }
  return data;
};

const planeNumber = (doc: Doc, path: string, noZeroDecimal?: boolean) => {
  let data = doc.get(path);
  if (typeof data === "number") {
    data = round((data * 180) / Math.PI, noZeroDecimal ? 1 : 0);
  }
  return data;
};

export const getDefaultSessionStartDate = async ({
  user,
  profileDocumentId,
}: {
  user: User;
  profileDocumentId: string;
}) => {
  let startDate = null;
  const collectionPath = `logs/${environment}/${user.uid}/data/sessions`;
  const q = query(
    collection(firestore, collectionPath),
    orderBy("session.timestamp", "desc"),
    where("session.profileDocumentId", "==", profileDocumentId),
    limit(30)
  );
  const querySnapshot = await getDocs(q);
  let docsArray = querySnapshot.docs;
  docsArray = docsArray.reverse();
  const startDoc = docsArray?.[0];
  if (startDoc) {
    startDate = dayjs(startDoc.get("session.timestamp").toDate());
  }
  return startDate;
};

export const getLatestProfileData = async (user: User) => {
  const collectionPath = `logs/${environment}/${user.uid}/data/sessions`;
  const q = query(
    collection(firestore, collectionPath),
    orderBy("session.timestamp", "desc"),
    limit(1)
  );
  const querySnapshot = await getDocs(q);
  let docsArray = querySnapshot.docs;
  docsArray = docsArray.reverse();
  let unitOfMeasure = "metric";
  let latestProfileId = null;
  const latestDoc = docsArray?.[0];
  if (latestDoc) {
    if (
      latestDoc.get(
        "session.settings.measurementSystemSettings.measurementSystem.rawValue"
      )
    ) {
      unitOfMeasure = latestDoc.get(
        "session.settings.measurementSystemSettings.measurementSystem.rawValue"
      );
    }
    if (latestDoc.get("session.settings.genericAppSettings.profile.id")) {
      latestProfileId = latestDoc.get("session.settings.genericAppSettings.profile.id");
    }
  }
  return { unitOfMeasure, latestProfileId };
};

export const getProfile = async (user: User) => {
  const collectionPath = `logs/${environment}/${user.uid}/data/profiles`;
  const q = query(collection(firestore, collectionPath));
  const querySnapshot = await getDocs(q);
  const profileList: Profile[] = [];
  let contactList: Contact[] = [];
  querySnapshot.forEach((doc) => {
    let docVal: Profile = {} as Profile;
    docVal["profileId"] = doc.get("profile.id");
    docVal["profileName"] = doc.get("profile.name");
    docVal["profileDocumentId"] = doc.get("profile.documentId");
    profileList.push(docVal);
  });
  for (const profile of profileList) {
    if (profile.profileDocumentId) {
      const profileContact = await getContact(user, profile.profileDocumentId);
      if (profileContact.length) {
        contactList = contactList.concat(profileContact);
      }
    }
  }
  return { profileList, contactList };
};

export const getAmbassadorData = async () => {
  const functions = getFunctions(app, "europe-west1");
  const callGetAmbassadorData = httpsCallable<ReqInterface, ResInterface>(
    functions,
    "getAmbassadorData"
  );
  const { data } = await callGetAmbassadorData();
  return data.result;
};

export const getContact = async (user: User, profileDocumentId: string) => {
  const collectionPath = `logs/${environment}/${user.uid}/data/profiles/${profileDocumentId}/contacts`;
  const q = query(collection(firestore, collectionPath));
  const querySnapshot = await getDocs(q);
  const contactList: Contact[] = [];
  querySnapshot.forEach((doc) => {
    let docVal: Contact = {} as Contact;
    docVal["contactMonitoringPermissionTimestamp"] = doc.get(
      "contactMonitoringPermissionTimestamp"
    );
    docVal["contactName"] = doc.get("contactName");
    docVal["contactProfileDocumentId"] = doc.get("contactProfileDocumentId");
    docVal["contactProfileId"] = doc.get("contactProfileId");
    docVal["contactUID"] = doc.get("contactUID");
    contactList.push(docVal);
  });
  return contactList.filter(
    (contact) => contact.contactMonitoringPermissionTimestamp
  );
};

export const getSwingsInSession = async ({
  contact,
  unitOfMeasure,
  sessionDocumentId,
  user,
}: {
  contact?: Contact;
  unitOfMeasure: string;
  sessionDocumentId: string;
  user: User;
}) => {
  const swingCollectionPath = `logs/${environment}/${
    contact?.contactUID ? contact.contactUID : user.uid
  }/data/swings`;
  const swings = await GetSwings({
    collectionPath: swingCollectionPath,
    unitOfMeasure,
    sessionDocumentId,
  });
  const filterSwings = swings.filter((swing) => swing.practiceSwing === false);
  return filterSwings;
};

export const getGoodSwingAverage = async ({
  profileDocumentId,
  user,
  contact,
}: {
  contact?: Contact;
  profileDocumentId: string;
  user: User;
}) => {
  const goodSwingAveragePath = `logs/${environment}/${
    contact?.contactUID ? contact.contactUID : user.uid
  }/data/profiles/${
    contact?.contactProfileDocumentId
      ? contact.contactProfileDocumentId
      : profileDocumentId
  }/summary`;
  const q = doc(firestore, goodSwingAveragePath, "swingDataNormalDeviation");
  const querySnapshot = await getDoc(q);
  const data = querySnapshot.data() as SwingDeviation;
  const driverAvg = data.clubAverages[1].powerScorePredictedChs;
  data.allClubAverages.powerScorePredictedChs = driverAvg;
  return data;
};

export default exportSwingsToFile;
