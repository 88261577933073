import React, { useCallback, useContext, useEffect, useState } from "react";
import { auth } from "./base";
import { User } from "@firebase/auth";
import {
  getProfile,
  getLatestProfileData,
  getAmbassadorData,
  getDefaultSessionStartDate,
} from "./FirebaseFunctions";
import { Profile } from "./models/profile";
import { AmbassadorData } from "./models/ambassador";
import { Contact } from "./models/contact";
import { Dayjs } from "dayjs";

interface AuthContextProps {
  currentUser: User | null;
  unitOfMeasure: string;
  profileList: Profile[];
  currentProfile: Profile;
  contactList: Contact[];
  currentContact: Contact;
  handleChangeProfile: (profileId: string) => void;
  handleChangeContact: (contactUID: string) => void;
  handleChangeUnitOfMeasure: (unitOfMeasure: string) => void;
  ambassadorData: AmbassadorData[];
  isInitialLoad: boolean;
  defaultStartDate: Dayjs | null;
}
export const AuthContext = React.createContext<AuthContextProps>({
  currentUser: null,
  unitOfMeasure: "",
  profileList: [],
  currentProfile: {} as Profile,
  contactList: [],
  currentContact: {} as Contact,
  handleChangeProfile: () => null,
  handleChangeContact: () => null,
  handleChangeUnitOfMeasure: () => null,
  ambassadorData: [],
  isInitialLoad: true,
  defaultStartDate: null,
});

export const useAuthContext = () => useContext(AuthContext);

export const AuthProvider = ({ children }: { children: JSX.Element }) => {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [pending, setPending] = useState(true);
  const [loadingUser, setLoadingUser] = useState(true);
  const [unitOfMeasure, setUnitOfMeasure] = useState("");
  const [profileList, setProfileList] = useState<Profile[]>([]);
  const [currentProfile, setCurrentProfile] = useState<Profile>({} as Profile);
  const [ambassadorData, setAmbassadorData] = useState<AmbassadorData[]>([]);
  const [currentContact, setCurrentContact] = useState<Contact>({} as Contact);
  const [contactList, setContactList] = useState<Contact[]>([]);
  const [defaultStartDate, setDefaultStartDate] = useState<null | Dayjs>(null);

  const handleChangeContact = useCallback(
    (contactUID: string) => {
      const selectedContact = contactList?.find(
        (contact) => contact.contactUID === contactUID
      );
      if (
        selectedContact &&
        selectedContact.contactUID !== currentContact.contactUID
      ) {
        setCurrentContact(selectedContact);
        if (currentProfile) {
          setCurrentProfile({} as Profile);
        }
      }
    },
    [setCurrentContact, currentContact, contactList, currentProfile]
  );

  const handleChangeProfile = useCallback(
    (profileId: string) => {
      const selectedProfile = profileList.find(
        (profile) => profile.profileId === profileId
      );
      if (
        selectedProfile &&
        selectedProfile.profileId !== currentProfile.profileId
      ) {
        setCurrentProfile(selectedProfile);
        if (currentContact) {
          setCurrentContact({} as Contact);
        }
      }
    },
    [setCurrentProfile, currentProfile, profileList, currentContact]
  );

  const handleChangeUnitOfMeasure = useCallback(
    (newUnitOfMeasure: string) => {
      if (newUnitOfMeasure && newUnitOfMeasure !== unitOfMeasure) {
        setUnitOfMeasure(newUnitOfMeasure);
      }
    },
    [setUnitOfMeasure, unitOfMeasure]
  );

  useEffect(() => {
    const subscribe = auth.onAuthStateChanged(async (user) => {
      try {
        setPending(true);
        setCurrentUser(user);
        setLoadingUser(false);
        if (user) {
          const { unitOfMeasure, latestProfileId } = await getLatestProfileData(
            user
          );
          const { profileList, contactList } = await getProfile(user);
          const ambassadorData = await getAmbassadorData();
          setUnitOfMeasure(unitOfMeasure);
          setProfileList(profileList);
          setContactList(contactList);
          const currentProfile = profileList.find(
            (profile) => profile.profileId === latestProfileId
          );
          if (currentProfile) {
            setCurrentProfile(currentProfile);
            const startDate = await getDefaultSessionStartDate({
              user,
              profileDocumentId: currentProfile.profileDocumentId,
            });
            if (startDate) {
              setDefaultStartDate(startDate);
            }
          }
          if (ambassadorData && ambassadorData.length) {
            setAmbassadorData(ambassadorData);
          }
        } else {
          setCurrentContact({} as Contact);
          setCurrentProfile({} as Profile);
        }
      } finally {
        setPending(false);
      }
    });
    return subscribe;
  }, []);

  if (loadingUser) {
    return <></>;
  }

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        unitOfMeasure,
        profileList,
        currentProfile,
        contactList,
        currentContact,
        ambassadorData,
        handleChangeProfile,
        handleChangeContact,
        handleChangeUnitOfMeasure,
        isInitialLoad: pending,
        defaultStartDate,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
